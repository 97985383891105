import React, { useRef, useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  Circle,
  Polygon,
  Autocomplete,
  InfoWindowF,
} from "@react-google-maps/api";

import {
  SafeLivestockPointer,
  UnsafeLivestockPointer,
  MarkerImg,
} from "../assets";
import useMapContext from "../hooks/useMapContext";
import { Skeleton } from ".";
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { CloseIcon, RoomIcon } from "../icons";

const MAP_KEY = "AIzaSyBoq0tt73i_mEUB4gsGN8_ClQpD9d9RqFE";
const GetMap = ({
  mapWidth,
  mapHeight,
  isLivestocks,
  livestockData,
  geofenceCoordinates,
  createGeoFence,
  isFenceCircular,
  userGeofence,
}) => {
  const {
    getGeolocationAddress,
    polygonPath,
    setPolygonPath,
    circleGeoFence,
    setCircleGeoFence,
    geoFenceType,
    setGeoFenceType,
    setIsGeoFenceSaved,
    mapRef,
    drawingManagerRef,
    dmPolygonRef,
    finalPoly,
    dmCircleRef,
    finalCircle,
    handleStartOver,
    openSnackbarAlert,
    handleFarmLocationDrag,
  } = useMapContext();
  const circleRef = useRef();
  const searchedLocation = useRef(null);
  const [currentLocation, setCurrentLocation] = useState({
    lat: 28.665916,
    lng: 77.227041,
  });
  const [showInfoWindow, setShowInfoWindow] = useState(null);
  const [adminMapCenter, setAdminMapCenter] = useState(null);
  const isUser = JSON.parse(localStorage.getItem("userData"))?.role == 3;
  const userName = JSON.parse(
    window?.localStorage?.getItem("userData")
  )?.userName;

  useEffect(() => {
    const detectAutoLocation = () => {
      if ("geolocation" in navigator) {
        navigator?.geolocation?.getCurrentPosition(function (position) {
          setCurrentLocation({
            lat: position?.coords?.latitude || 28.665916,
            lng: position?.coords?.longitude || 77.227041,
          });
        });
      } else {
        toast.error("Error:Make sure to allow location access");
      }
    };

    if (!geofenceCoordinates?.farmLat || !geofenceCoordinates?.farmLng) {
      detectAutoLocation();
    }
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: MAP_KEY,
    libraries: ["drawing", "places"],
  });

  useEffect(() => {
    if (
      (createGeoFence || localStorage.getItem("geofence") === "edit") &&
      !isFenceCircular
    ) {
      drawingManagerRef.current?.setMap(mapRef.current);
      drawingManagerRef?.current?.setOptions({ drawingControl: true });
      drawingManagerRef?.current?.setDrawingMode(
        window.google.maps.drawing.OverlayType.POLYGON
      );
      // drawingManagerRef?.current?.setDr(true)
      finalCircle.current?.setMap(null);
      finalPoly.current?.setMap(null);
    } else {
      drawingManagerRef.current?.setMap(null);
    }
  }, [
    createGeoFence,
    drawingManagerRef.current,
    mapRef.current,
    isFenceCircular,
  ]);

  const handlePolygonEdit = (polygon) => {
    const paths = getPolygonPaths(polygon);
    setPolygonPath(paths);
  };

  const handleCircleEdit = (circle) => {
    const circleFence = {
      radius: circle?.radius || 0,
      position: {
        lat: circle?.center?.lat() || 0,
        lng: circle?.center?.lng() || 0,
      },
    };
    setCircleGeoFence(circleFence);
  };

  const getPolygonPaths = (polygon) => {
    const polyArray = polygon.getPath().getArray();
    const paths = [];
    polyArray.forEach((path) => {
      paths.push({ lat: path.lat(), lng: path.lng() });
    });
    return paths;
  };

  const onPolygonComplete = (event) => {
    if (event.type == window?.google.maps.drawing.OverlayType.POLYGON) {
      setGeoFenceType("polygon");
      const polygon = event.overlay;

      // Disable drawing mode
      drawingManagerRef.current.setDrawingMode(null);

      // Allow the user to edit the drawn polygon
      polygon.setEditable(true);

      // Remove drawing control
      drawingManagerRef.current.setOptions({
        drawingControl: false,
      });

      dmPolygonRef.current = polygon;
      const polyArray = getPolygonPaths(polygon);
      window?.google.maps.event.addListener(polygon, "mouseup", () =>
        handlePolygonEdit(polygon)
      );
      window?.google.maps.event.addListener(polygon, "dragend", () =>
        handlePolygonEdit(polygon)
      );
      setPolygonPath(polyArray);
      // You can add listeners to the polygon as well
      //   window?.google.maps.event.addListener(polygon, "click", function () {
      //     // Handle click event on the polygon
      //   });
    } else if (event.type == window?.google.maps.drawing.OverlayType.CIRCLE) {
      setGeoFenceType("circle");
      const circle = event.overlay;
      dmCircleRef.current = circle;

      // Disable drawing mode
      drawingManagerRef.current.setDrawingMode(null);

      // Allow the user to edit the drawn polygon
      circle.setEditable(true);

      // Remove drawing control
      drawingManagerRef.current.setOptions({
        drawingControl: false,
      });

      const circleFence = {
        radius: circle?.radius || 0,
        position: {
          lat: circle?.center?.lat() || 0,
          lng: circle?.center?.lng() || 0,
        },
      };
      setCircleGeoFence(circleFence);

      window?.google.maps.event.addListener(circle, "center_changed", () =>
        handleCircleEdit(circle)
      );
      window?.google.maps.event.addListener(circle, "radius_changed", () =>
        handleCircleEdit(circle)
      );
    }
  };

  // useEffect(() => {
  //   let timeout = setTimeout(() => mapRef.current?.setZoom(16), 500);

  //   return () => clearTimeout(timeout);
  // }, [adminMapCenter]);

  const onLoad = useCallback(function callback(map) {
    if (userGeofence?.length) {
      var markers = userGeofence?.map((ele) => ({
        lat: ele?.farmLat || 28.665916,
        lng: ele?.farmLng || 77.227041,
      }));
      const newMarkers = markers.filter((ele) => ele?.lat !== undefined);
      var bounds = new window.google.maps.LatLngBounds();
      for (var i = 0; i < newMarkers.length; i++) {
        bounds.extend(newMarkers[i]);
      }
      map.fitBounds(bounds);
      // map.setCenter({
      //   lat: bounds?.getCenter()?.lat(),
      //   lng: bounds?.getCenter()?.lng(),
      // });
      // setAdminMapCenter(bounds?.getCenter());
    } else {
      console.log(
        Number(currentLocation?.lat),
        "Number(currentLocation?.lat",
        Number(geofenceCoordinates?.farmLat),
        " Number(geofenceCoordinates?.farmLat)"
      );
      map.setCenter({
        lat:
          Number(geofenceCoordinates?.farmLat) || Number(currentLocation?.lat),
        lng:
          Number(geofenceCoordinates?.farmLng) || Number(currentLocation?.lng),
      });
      map.setZoom(5);
    }

    mapRef.current = map;

    // ADD A DRAWING MANAGER TO MAP
    let drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          window.google.maps.drawing.OverlayType.POLYGON,
          // window.google.maps.drawing.OverlayType.CIRCLE,
        ],
      },
      polygonOptions: {
        fillColor: "#FF0000",
        fillOpacity: 0.5,
        strokeWeight: 2,
        clickable: true,
        editable: true,
      },
    });
    // CREATE A REF TO DRAWING MANAGER
    drawingManagerRef.current = drawingManager;
    // CONNECT THE DM WITH MAP INSTANCE

    // LISTEN FOR EVENT WHEN POLYGON COMPLETE
    window.google.maps.event.addListener(
      drawingManager,
      "overlaycomplete",
      onPolygonComplete
    );
  }, []);

  const onPolygonSave = () => {
    if (geoFenceType && (circleGeoFence?.radius || polygonPath?.length > 2)) {
      localStorage.setItem("hideCancel", "true");
      const polygon = new window.google.maps.Polygon({
        paths: polygonPath,
        strokeColor: "#06B95F",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#06B95F",
        fillOpacity: 0.35,
        clickable: false,
      });
      const circle = new window.google.maps.Circle({
        center: circleGeoFence?.position,
        radius: circleGeoFence?.radius,
        strokeColor: "#06B95F",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#06B95F",
        fillOpacity: 0.35,
        clickable: false,
      });

      const farmLocation = {
        lat: Number(geofenceCoordinates.farmLat),
        lng: Number(geofenceCoordinates.farmLng),
      };

      if (geoFenceType === "polygon") {
        const isInside = window?.google?.maps?.geometry?.poly?.containsLocation(
          farmLocation,
          polygon
        );
        if (isInside) {
          finalPoly.current = polygon;
          polygon.setMap(mapRef.current);
          dmPolygonRef.current.setMap(null);
          drawingManagerRef.current.setMap(null);
          setIsGeoFenceSaved(true);
        } else {
          toast.error("the farm location must be inside geofence");
        }
      } else {
        const distance =
          window.google.maps.geometry.spherical.computeDistanceBetween(
            farmLocation,
            circleGeoFence?.position
          );

        if (distance <= circleGeoFence?.radius) {
          finalCircle.current = circle;
          circle.setMap(mapRef.current);
          dmCircleRef.current.setMap(null);
          drawingManagerRef.current.setMap(null);
          setIsGeoFenceSaved(true);
        } else {
          toast.error("the farm location must be inside geofence");
        }
      }
    } else {
      toast.error("error", "please create a geofence first");
    }
  };

  const onUnmount = useCallback(function callback(map) {
    mapRef.current = null;
  }, []);

  const getLivestockImg = (status, role) => {
    // if (role === "user") return MarkerImg;
    return status?.toLowerCase() === "safe"
      ? SafeLivestockPointer
      : UnsafeLivestockPointer;
  };

  let timer;
  const handleMapCenterChanged = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      getGeolocationAddress(
        false,
        mapRef.current?.center?.lat(),
        mapRef.current?.center?.lng()
      );
    }, 500);
  };

  function GetLatLong(address) {
    var geocoder = new window.google.maps.Geocoder();

    return geocoder.geocode(
      {
        address: address,
      },
      function (results, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
          return results[0].geometry.location;
        }
      }
    );
  }

  const onPlaceChange = async (e) => {
    let location = await GetLatLong(searchedLocation.current.value);
    const lat = location?.results[0]?.geometry?.location?.lat();
    const lng = location?.results[0]?.geometry?.location?.lng();

    if (lat && lng) {
      getGeolocationAddress(false, lat, lng);
    } else {
      toast.error("No location found, location must be from the dropdown");
    }
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: mapWidth,
        height: mapHeight,
      }}
      options={{
        // streetViewControl: false,
        // zoomControl: false,
        mapTypeControl: !createGeoFence,
        // mapTypeControl: false,
        // fullscreenControl: false,
        mapTypeId: "hybrid",
      }}
      onDrag={
        !localStorage.getItem("farmLocation") ? handleMapCenterChanged : null
      }
      onClick={(e) =>
        !localStorage.getItem("farmLocation")
          ? getGeolocationAddress(false, e.latLng.lat(), e.latLng.lng())
          : null
      }
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {!localStorage.getItem("farmLocation") && (
        <Autocomplete onPlaceChanged={onPlaceChange}>
          <TextField
            inputRef={searchedLocation}
            size="small"
            label="Search locations"
            variant="filled"
            InputProps={{
              sx: { fontSize: "16px" },
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => (searchedLocation.current.value = "")}
                >
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              background: "#fff",
              position: "absolute",
              width: "250px",
              left: "50%",
              top: "2%",
              transform: "translateX(-50%)",
              borderRadius: "8px",
            }}
          />
        </Autocomplete>
      )}
      {isLivestocks &&
        livestockData &&
        livestockData?.map(({ id, position, safeUnsafeStatus, role, name }) => (
          <Marker
            key={id}
            position={{
              lat: Number(position?.lat),
              lng: Number(position?.lng),
            }}
            onClick={() =>
              setShowInfoWindow({
                id,
                position,
                name: isUser ? userName : name || "N/A",
              })
            }
            icon={{
              url: getLivestockImg(safeUnsafeStatus, role),
              scaledSize:
                window && window.google && role
                  ? new window.google.maps.Size(35, 35)
                  : new window.google.maps.Size(45, 35),
            }}
          />
        ))}
      {localStorage.getItem("farmLocation") &&
        (window.location.pathname === "/" && userGeofence?.length ? (
          <Marker
            key="dcdcde323ddccddc3ded3de"
            label={{
              color: "#fff",
              text: "A",
            }}
            draggable={!localStorage.getItem("farmLocation")}
            onDrag={handleFarmLocationDrag}
            title="marker"
            position={{
              lat: Number(geofenceCoordinates?.farmLat) || 0,
              lng: Number(geofenceCoordinates?.farmLng) || 0,
            }}
          />
        ) : (
          <Marker
            key="dcdcde323ddccddc3ded3de"
            draggable={!localStorage.getItem("farmLocation")}
            onDrag={handleFarmLocationDrag}
            title="marker"
            position={{
              lat: Number(geofenceCoordinates?.farmLat) || 0,
              lng: Number(geofenceCoordinates?.farmLng) || 0,
            }}
          />
        ))}
      {/* // for user  */}
      {localStorage.getItem("geofence") !== "edit" &&
      localStorage.getItem("farmLocation") ? (
        geofenceCoordinates?.geofenceType?.toLowerCase() === "polygon" ||
        geofenceCoordinates?.geoFenceType?.toLowerCase() === "polygon" ? (
          <Polygon
            paths={
              // geofenceCoordinates?.coordinates
              geofenceCoordinates?.polygon || geofenceCoordinates?.coordinates
            }
            options={{
              strokeColor: "#06B95F",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#06B95F",
              fillOpacity: 0.35,
              clickable: false,
            }}
          />
        ) : (
          <Circle
            center={{
              lat: Number(geofenceCoordinates?.farmLat),
              lng: Number(geofenceCoordinates?.farmLng),
            }}
            radius={
              localStorage.getItem("geofence") === "edit"
                ? 0
                : Number(geofenceCoordinates?.radius)
            }
            options={{
              strokeColor: "#06B95F",
              strokeOpacity: 1,
              strokeWeight: 2,
              fillColor: "#06B95F",
              fillOpacity: 0.35,
              clickable: false,
              draggable: false,
              editable: false,
              visible: true,
            }}
          />
        )
      ) : null}
      {showInfoWindow && (
        <InfoWindowF
          key={showInfoWindow?.id}
          position={showInfoWindow?.position}
          onCloseClick={() => setShowInfoWindow(null)}
        >
          <h3>{showInfoWindow?.name}</h3>
        </InfoWindowF>
      )}
      {/* user's geo fence -> ofa */}
      {/* {console.log(userGeofence, "hfghsdghf")}  */}
      {userGeofence?.map((ele) => {
        return ele?.geoFenceType?.toLowerCase() === "polygon" ? (
          <>
            <Marker
              key="dcdcde323ddccddc3ded3de"
              title="marker"
              onClick={() =>
                setShowInfoWindow({
                  id: `${ele?.farmLat}${Math.random()?.toString()?.slice(2)}`,
                  position: { lat: ele?.farmLat, lng: ele?.farmLng },
                  name: isUser ? userName : ele?.name || "N/A",
                })
              }
              label={{ color: "#fff", text: "U" }}
              position={{
                lat: Number(ele?.farmLat) || 0,
                lng: Number(ele?.farmLng) || 0,
              }}
            />
            <Polygon
              paths={ele?.polygon}
              options={{
                strokeColor: "#06B95F",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#06B95F",
                fillOpacity: 0.35,
                clickable: false,
              }}
            />
          </>
        ) : (
          <>
            <Marker
              key="dcdcde323ddccddc3ded3de"
              title="marker"
              label={{ color: "#fff", text: "U" }}
              onClick={() =>
                setShowInfoWindow({
                  id: `${ele?.farmLat}${Math.random()?.toString()?.slice(2)}`,
                  position: { lat: ele?.farmLat, lng: ele?.farmLng },
                  name: isUser ? userName : ele?.name || "N/A",
                })
              }
              position={{
                lat: Number(ele?.farmLat) || 0,
                lng: Number(ele?.farmLng) || 0,
              }}
              icon={{
                // url: getLivestockImg(safeUnsafeStatus, role),
                scaledSize:
                  window && window.google
                    ? new window.google.maps.Size(35, 35)
                    : new window.google.maps.Size(45, 35),
              }}
            />

            <Circle
              center={{
                lat: Number(ele?.farmLat),
                lng: Number(ele?.farmLng),
              }}
              radius={Number(ele?.radius)}
              options={{
                strokeColor: "#06B95F",
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: "#06B95F",
                // fillColor: "#FD3730",
                fillOpacity: 0.35,
                clickable: false,
                draggable: false,
                editable: false,
                visible: true,
              }}
            />
          </>
        );
      })}
      {localStorage.getItem("geofence") === "edit" && isFenceCircular && (
        <Circle
          center={{
            lat: Number(geofenceCoordinates?.farmLat),
            lng: Number(geofenceCoordinates?.farmLng),
          }}
          radius={Number(geofenceCoordinates?.radius)}
          options={{
            strokeColor: "#06B95F",
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: "#06B95F",
            fillOpacity: 0.35,
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
          }}
        />
      )}
      {!localStorage.getItem("farmLocation") && (
        <IconButton
          sx={{
            fontSize: "1.2rem",
            fontWeight: "bold",
            position: "absolute",
            top: "47%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            pointerEvents: "none",
          }}
        >
          <RoomIcon sx={{ color: "#FF0505", fontSize: "45px" }} />
        </IconButton>
      )}
      {createGeoFence &&
        !isFenceCircular &&
        localStorage.getItem("farmLocation") && (
          <Stack direction={"row"} gap={1} p={1}>
            <Button
              variant="contained"
              sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
              onClick={handleStartOver}
            >
              Start Over
            </Button>
            <Button
              variant="contained"
              onClick={onPolygonSave}
              sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
            >
              Save
            </Button>
          </Stack>
        )}
    </GoogleMap>
  ) : (
    <Skeleton width={mapWidth} height={mapHeight} />
  );
};

export default GetMap;
